require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('chart.js')


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


// import javascripts
import $ from 'jquery'
import 'bootstrap'

// import stylesheets
import 'chart.js/dist/Chart.css'
import '../stylesheets/atgcoin'

// import shared
import '../shared/bootstrap.js'
import '../shared/clipboard.js'
import '../shared/turbolinks.js'
import '../shared/bs_custom_file_input.js'
import '../shared/ajax.js'
import '../shared/js_noaction.js'
import '../shared/transactions_select_type.js'


window.jQuery = $
window.$ = $


// office
document.addEventListener('turbolinks:load', ()=> {
	let element = document.getElementById('coin-chart')

	if (element)
	{
		let context = element.getContext('2d')
		let coin_periods = JSON.parse(element.dataset.coinPeriods)

		let dates = []
		let costs = []

		Object.entries(coin_periods).forEach(entry => {
			dates.push(entry[0])
			costs.push(entry[1])
		})

		let data = {
			labels: dates,
			datasets: [{
				backgroundColor: '#3bb75b',
				borderColor: '#3bb75b',
				data: costs,
				fill: false,
				lineTension: 0
			}]
		}

		let chart = new Chart(context, {
			type: 'line',
			data: data,
			options: {
				legend: {
					labels: false
				}
			}
		})
	}
})

// office#atgcoin_plus
document.addEventListener('turbolinks:load', ()=> {
	var input = document.getElementsByClassName('form-atgcoin-plus-buying__amount')[0]
	let button = document.getElementsByClassName('form-atgcoin-plus-buying__button')[0]

	if (input && button) {
		input.oninput = ()=> {
			if (Number(input.value) <= Number(button.dataset.maximum)) {
				button.innerHTML = button.dataset.textValue + ' $' + Number(button.dataset.price) * Number(input.value)
			} else {
				button.innerHTML = button.dataset.textInitial
			}
		}
	}
})

// office#atgcoin_gold
document.addEventListener('turbolinks:load', ()=> {
	var input = document.getElementsByClassName('form-atgcoin-gold-buying__amount')[0]
	let button = document.getElementsByClassName('form-atgcoin-gold-buying__button')[0]

	if (input && button) {
		input.oninput = ()=> {
			if (Number(input.value) <= Number(button.dataset.maximum)) {
				button.innerHTML = button.dataset.textValue + ' $' + Number(button.dataset.price) * Number(input.value)
			} else {
				button.innerHTML = button.dataset.textInitial
			}
		}
	}
})
